import React, { useEffect, useState } from "react";
import JournalButton from "../Components/ui/buttons/JournalButton";
import ExpandableToolMenu from "../Components/ui/buttons/ExpandableToolMenu";
import PatientBox from "../Patients/PatientBox";
import { useSelector } from "react-redux";
import "./PatientRecordBox.css";
import "../Components/ui/card/Card.scss";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  clearSelectedExaminations,
  clearOpenedPatient,
  setOpenedPatient,
  removeOpenedPatient,
  setSelectAllExaminationsFlag,
} from "../Redux/actions";
import { useHistory } from "react-router-dom";
import PatientName from "../Components/PatientName/PatientName";
import "./stylesheets/patientRecordBox.scss";
import { useKeycloak } from "@react-keycloak/web";
import { OpenExamInPmView } from "../Utilities/ClientServices/ClientServices";
import { NotificationManager } from "react-notifications";

//buttons actions imports
import DicomSendModal from "../Components/DicomSendModal/DicomSendModal";
import DeletePatientModal from "../Components/DeletePatientModal/DeletePatientModal";
import MoveExamModal from "../Components/MoveExamModal/MoveExamModal";
import DeleteExaminationModal from "../Components/DeleteExaminationModal/DeleteExaminationModal";
import ExportSelectedExaminationsModal from "../Components/ExportSelectedExaminationsModal/ExportSelectedExaminationsModal";
import BurnCdModal from "../Components/BurnCdModal/BurnCdModal";
import ImportFileModal from "../Components/ImportFileModal/ImportFileModal";
import UnhookImagesModal from "../Components/UnhookImagesModal/UnhookImagesModal";
import useWindowSize from "../Utilities/Window/windowSize";
import AuthorizedElement from "../Utilities/AuthorizedElement";
import PatientWebAccessModal from "../Components/PatientWebAccessModal/PatientWebAccessModal";
import { MakeGetObject } from "../Utilities/AuthHeader";
import useQuery from "../Utilities/Hooks/useQuery"

function PatRecordBox() {
  const selectedPatients = useSelector(
    (state) => state.examManager.openedPatients
  );

  const selectedExaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );

  const { keycloak } = useKeycloak();
  const history = useHistory();
  const [displayedPatient, setDisplayedPatinet] = useState(undefined);
  const { t } = useTranslation(["patientrecordbuttons", "common"]);
  const dispatch = useDispatch();
  const paramPat = new URLSearchParams(window.location.search).get("patid");
  const searchParams = useQuery();
  const { width } = useWindowSize();

  if (searchParams.get("PatientIDs")) {
    const patid_arr = searchParams.get("PatientIDs").split(",");

    if (patid_arr && patid_arr.length === 1) {
      patid_arr.forEach((patientid) => {
        fetch(
          `
        ${
          window.conf.SERVER_CONFIG.BASE_URL
        }/ris/patients?&search=${encodeURIComponent(
            JSON.stringify({ field: "patientid", op: "like", value: patientid })
          )}`,
          MakeGetObject(keycloak)
        )
          .then((response) => response.json())
          .then((rdata) => {
            if (rdata.patients) {
              if (rdata.patients[0]) {
                const dapatid = rdata.patients[0].id;
                const selected_pat_ids = selectedPatients;
                if (dapatid && !selected_pat_ids.includes(dapatid)) {
                  dispatch(setOpenedPatient(dapatid));
                }
              }
            } else {
              //Not existing patient, create new one
              if (
                searchParams.get("department_id") &&
                searchParams.get("birth_date") &&
                searchParams.get("patientssex") &&
                searchParams.get("patientsname") &&
                searchParams.get("patientsvorname") &&
                searchParams.get("street") &&
                searchParams.get("zip") &&
                searchParams.get("city")
              )
               {
                const new_patient = {
                  id: patientid,
                  kontoid: searchParams.get("department_id"),
                  gebdat: searchParams.get("birth_date"),
                  patientssex: searchParams.get("patientssex"),
                  patientsname: searchParams.get("patientsname"),
                  patientsvorname: searchParams.get("patientsvorname"),
                  street: searchParams.get("street"),
                  zip: searchParams.get("zip"),
                  city: searchParams.get("city"),
                };

                const requestOptions = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ' + ${keycloak.token}`,
                  },
                  body: JSON.stringify({
                    ...new_patient,
                  }),
                };
                fetch(
                  `${window.conf.SERVER_CONFIG.BASE_URL}/ris/patients`,
                  requestOptions
                )
                  .then((response) => {
                    // check for error response
                    if (!response.ok) {
                      // get error message from body or default to response status
                      const error = response.status;
                      return Promise.reject(error);
                    } else {
                      return response.json();
                    }
                  })
                  .then((rjson) => {
                    //dispatch(setOpenedPatient(rjson.id));
                    NotificationManager.success(
                      `${t("notification.addSuccess")}`
                    );
                  })
                  .catch((error) => {
                    NotificationManager.error(
                      `${t("notification.addError")} ${error}`
                    );
                  });
              }
            }
          });
      });
    }
  }

  useEffect(() => {
    dispatch(clearSelectedExaminations());
    if (paramPat) {
      dispatch(setOpenedPatient(paramPat));
    }
    if (keycloak.hasRealmRole("pm_patient")) {
      dispatch(setOpenedPatient(keycloak?.tokenParsed?.pmed_patient_id));
    }
  }, []);

  useEffect(() => {
    if (selectedPatients.length > 0) {
      setDisplayedPatinet(selectedPatients[selectedPatients.length - 1]);
    } else {
      setDisplayedPatinet(undefined);
    }
  }, [selectedPatients]);

  // KK: Const patient_record_buttons was moved to function PatRecordBox, because problem with translation.
  const patient_record_buttons = [
    {
      id: "DeletePatient",
      label: "Delete Patient",
      hint: t("menuButtons.DeletePatient"),
      icon: "fas fa-trash",
      commandname: "deletePatient",
      modal_to_render: DeletePatientModal,
      allowed_roles: ["pm_admin", "pm_user"],
    },
    {
      id: "ClearSelectedPatients",
      label: "Clear Selected Patients",
      hint: t("menuButtons.ClearSelectedPatientsHint"),
      icon: "fas fa-user-minus",
      onClick: () => {
        dispatch(clearSelectedExaminations());
        dispatch(clearOpenedPatient());
      },
      commandname: "clearSelectedPatients",
      allowed_roles: ["pm_admin", "pm_user", "pm_refphys", "pm_facility"],
    },
    {
      id: "SelectAllExaminations",
      label: "Clear Selected Examinations",
      hint: t("menuButtons.SelectAllExaminationsHint"),
      icon: "fas fa-check",
      onClick: () => {
        dispatch(setSelectAllExaminationsFlag(true));
      },
      commandname: "selectAllExaminations",
      allowed_roles: [
        "pm_admin",
        "pm_user",
        "pm_refphys",
        "pm_facility",
        "pm_patient",
      ],
    },
    {
      id: "ClearSelectedExaminations",
      label: "Clear Selected Examinations",
      hint: t("menuButtons.ClearSelectedExaminationsHint"),
      icon: "fas fa-check-double",
      onClick: () => {
        dispatch(clearSelectedExaminations());
      },
      commandname: "clearSelectedExaminations",
      allowed_roles: [
        "pm_admin",
        "pm_user",
        "pm_refphys",
        "pm_facility",
        "pm_patient",
      ],
    },
    {
      id: "OpenSelectedExaminationsInPmView",
      label: "Open Selected Examinations In PmView",
      hint: t("menuButtons.OpenSelectedExaminationsInPmView"),
      icon: "fas fa-eye",
      onClick: () => {
        if (selectedExaminations.length === 0) {
          NotificationManager.warning("Select examinations first");
          return;
        }
        selectedExaminations.forEach((exam) => {
          OpenExamInPmView(null, exam.suid);
        });
      },
      commandname: "openSelectedExamsInPmView",
      allowed_roles: [
        "pm_admin",
        "pm_user",
        "pm_refphys",
        "pm_facility",
        "pm_patient",
      ],
    },
    {
      id: "StartExamPlanning",
      label: "Start exam planner",
      hint: t("menuButtons.startExamPlanerHint"),
      icon: "fas fa-stethoscope",
      onClick: () => {
        dispatch(clearOpenedPatient());
        dispatch(setOpenedPatient(displayedPatient));
        history.push("/ExamPlanner");
      },
      commandname: "startExamPlanner",
      allowed_roles: ["pm_admin", "pm_user", "pm_refphys", "pm_facility"],
    },
    // {
    //   id: "ImportCD",
    //   label: "Import from CD",
    //   hint: t("menuButtons.importCdHint"),
    //   icon: "fas fa-compact-disc",
    //   commandname: "startCDImport",
    //   modal_to_render: null,
    // },
    // {
    //   id: "ImportingTools",
    //   label: "Importing tools",
    //   hint: t("menuButtons.importToolsHint"),
    //   icon: "fas fa-tools",
    //   commandname: "displayImportingTools",
    //   modal_to_render: ImportToolsModal,
    // },
    {
      id: "Burn",
      label: "Burn",
      hint: t("menuButtons.burnCdHint"),
      icon: "fas fa-fire",
      commandname: "burnExam",
      modal_to_render: BurnCdModal,
      allowed_roles: ["pm_admin", "pm_user", "pm_refphys", "pm_facility"],
    },
    {
      id: "ImportFile",
      label: "Import file",
      hint: t("menuButtons.ImportFileHint"),
      icon: "fas fa-file-import",
      commandname: "importFile",
      modal_to_render: ImportFileModal,
      allowed_roles: [
        "pm_admin",
        "pm_user",
        "pm_refphys",
        "pm_facility",
        "pm_patient",
      ],
    },
    {
      id: "More",
      label: "More",
      hint: t("menuButtons.moreHint"),
      icon: "fas fa-ellipsis-h",
      allowed_roles: [
        "pm_admin",
        "pm_user",
        "pm_refphys",
        "pm_facility",
        "pm_patient",
      ],
      buttons: [
        // {
        //   id: "ExamDiscImport",
        //   category: t("subMenuButtons.mediaImportCategory"),
        //   look: "button",
        //   label: "Media import",
        //   hint: t("subMenuButtons.mediaImportDriveHint"),
        //   icon: "fas fa-compact-disc",
        //   commandname: "importDisc",
        //   modal_to_render: null,
        // },
        // {
        //   id: "ImportFile",
        //   category: t("subMenuButtons.mediaImportCategory"),
        //   look: "button",
        //   label: "Import file",
        //   hint: t("subMenuButtons.importHint"),
        //   icon: "fas fa-upload",
        //   commandname: "importFile",
        //   modal_to_render: null,
        // },
        // {
        //   id: "LinkImport",
        //   category: t("subMenuButtons.mediaImportCategory"),
        //   look: "button",
        //   label: "Link import",
        //   hint: t("subMenuButtons.mediaImportLinkHint"),
        //   icon: "fas fa-link",
        //   commandname: "importLink",
        //   modal_to_render: null,
        // },
        // {
        //   id: "ScanAndImport",
        //   category: t("subMenuButtons.mediaImportCategory"),
        //   look: "button",
        //   label: "Scan",
        //   hint: t("subMenuButtons.mediaImportScanHint"),
        //   icon: "fas fa-print",
        //   commandname: "scanImport",
        //   modal_to_render: null,
        // },
        {
          id: "SendExamToDicomHost",
          category: t("subMenuButtons.mediaExportCategory"),
          look: "button",
          label: "Send Dicom",
          hint: t("subMenuButtons.mediaExportSendDicom"),
          icon: "fas fa-paper-plane",
          commandname: "sendDicom",
          modal_to_render: DicomSendModal,
          allowed_roles: ["pm_admin", "pm_user"],
        },
        // {
        //   id: "ExportExamToDisc",
        //   category: t("subMenuButtons.mediaExportCategory"),
        //   look: "button",
        //   label: "Export",
        //   hint: t("subMenuButtons.mediaExportSelectedHint"),
        //   icon: "fas fa-file-export",
        //   commandname: "exportToMedia",
        //   modal_to_render: null,
        // },
        {
          id: "MultipatientExport",
          category: t("subMenuButtons.mediaExportCategory"),
          look: "button",
          label: "Multipatient export",
          hint: t("subMenuButtons.mediaExportZip"),
          icon: "fas fa-file-archive",
          commandname: "exportMultipatMedia",
          modal_to_render: ExportSelectedExaminationsModal,
          allowed_roles: [
            "pm_admin",
            "pm_user",
            "pm_refphys",
            "pm_facility",
            "pm_patient",
          ],
        },
        // {
        //   id: "USBExport",
        //   category: t("subMenuButtons.mediaExportCategory"),
        //   look: "button",
        //   label: "USB export.",
        //   hint: t("subMenuButtons.mediaExportUsbHint"),
        //   icon: "fab fa-usb fa-sm",
        //   commandname: "exportUSBMedia",
        //   modal_to_render: ExportSelectedExaminationsModal,
        // },
        {
          id: "MoveToOtherPatient",
          category: t("subMenuButtons.studyManageCategory"),
          look: "button",
          label: "Move",
          hint: t("subMenuButtons.studyManageMoveHint"),
          icon: "fas fa-angle-double-right",
          commandname: "moveEntryToAnotherPatient",
          modal_to_render: MoveExamModal,
          allowed_roles: ["pm_admin", "pm_user"],
        },
        {
          id: "unhookImage",
          category: t("subMenuButtons.studyManageCategory"),
          look: "button",
          label: "Unhook",
          hint: t("subMenuButtons.studyManageUnhookHint"),
          icon: "fas fa-anchor",
          commandname: "unhookImage",
          modal_to_render: UnhookImagesModal,
          allowed_roles: ["pm_admin", "pm_user"],
        },
        {
          id: "delete",
          category: t("subMenuButtons.studyManageCategory"),
          look: "button",
          label: "Delete",
          hint: t("subMenuButtons.studyManageDelete"),
          icon: "fas fa-trash-alt",
          commandname: "delete",
          modal_to_render: DeleteExaminationModal,
          allowed_roles: ["pm_admin", "pm_user"],
        },
        {
          id: "patientWebAccess",
          category: t("subMenuButtons.studyManageCategory"),
          look: "button",
          label: "Web Access",
          hint: t("subMenuButtons.patientWebAccessHint"),
          icon: "fas fa-qrcode",
          commandname: "patientWebAccess",
          modal_to_render: PatientWebAccessModal,
          allowed_roles: ["pm_admin", "pm_user", "pm_refphys", "pm_facility"],
        },
      ],
    },
  ];

  const _getButtonComponent = (pat_button_definition) => {
    if (pat_button_definition.buttons) {
      return (
        <AuthorizedElement
          roles={pat_button_definition.allowed_roles}
          key={pat_button_definition.id}
        >
          <ExpandableToolMenu
            buttons={pat_button_definition.buttons}
            label={pat_button_definition.label}
            icon={pat_button_definition.icon}
            hint={pat_button_definition.hint}
            key={pat_button_definition.id}
          />
        </AuthorizedElement>
      );
    } else {
      return (
        <AuthorizedElement
          roles={pat_button_definition.allowed_roles}
          key={pat_button_definition.id}
        >
          <JournalButton
            label={pat_button_definition.label}
            icon={pat_button_definition.icon}
            hint={pat_button_definition.hint}
            onClick={pat_button_definition.onClick}
            modal={
              pat_button_definition?.modal_to_render !== undefined
                ? pat_button_definition?.modal_to_render
                : null
            }
          />
        </AuthorizedElement>
      );
    }
  };

  const tabClickHandler = (e, patientId) => {
    if (e.button === 1) {
      dispatch(removeOpenedPatient(patientId));
    } else {
      setDisplayedPatinet(patientId);
    }
  };

  return (
    <div
      className="d-flex flex-column bg-light rounded"
      style={{
        maxHeight: width > 1199 ? "99vh" : "89vh",
        alignItems: "stretch",
        overflowY: "auto",
        flex: window.conf.PATIENT_JOURNAL.WORKLIST_HIDDEN ? 2 : 1,
      }}
    >
      {selectedPatients.length > 1 && (
        <div className="tabs">
          {selectedPatients.map((patient_id, key) => (
            <button
              onClick={(e) => tabClickHandler(e, patient_id)}
              onAuxClick={(e) => tabClickHandler(e, patient_id)}
              key={`patienttabkey${key}`}
              className={
                displayedPatient === patient_id
                  ? "selected  tablinks px-2 py-2"
                  : "tablinks px-2 py-2"
              }
            >
              <PatientName
                key={`patientnamekey${key}`}
                patient_id={patient_id}
                is_active={patient_id === displayedPatient}
              />
            </button>
          ))}
        </div>
      )}
      <div className="scrollable-area">
        {displayedPatient !== undefined && (
          <PatientBox
            id={displayedPatient}
            key={displayedPatient}
            patid={displayedPatient}
          />
        )}
      </div>
      <div
        className="d-flex mt-1 mb-2 justify-content-center"
        style={{ flexWrap: "wrap", gap: "1px" }}
      >
        {patient_record_buttons.map((pat_button) =>
          _getButtonComponent(pat_button)
        )}
      </div>
    </div>
  );
}

export default PatRecordBox;
