import { Button } from "react-bootstrap";
import styles from "./ContentWrapper.module.scss";
import { EXAM_HELPER_ROUTES } from "../../../Pages/Consts/ExamHelperRoutes";
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { useMemo } from "react";

function ContentWrapper({ children }) {
  const url = useRouteMatch();
  const examId = url.params?.examId;
  const matchingRoute = useMemo(() => {
    return EXAM_HELPER_ROUTES.find((route) => {
      if (route.path) {
        return route.path.includes(url.path);
      } else {
        return route.subMenuItems.find((subMenuItem) => {
          return subMenuItem.path.includes(url.path);
        });
      }
    });
  }, [url]);
  const matchingRouteIndex = EXAM_HELPER_ROUTES.indexOf(matchingRoute);
  const prevLink =
    matchingRouteIndex > 0 && EXAM_HELPER_ROUTES[matchingRouteIndex - 1]?.path
      ? EXAM_HELPER_ROUTES[matchingRouteIndex - 1].link
      : EXAM_HELPER_ROUTES[matchingRouteIndex - 1]?.subMenuItems[0].link;
  const nextLink =
    matchingRouteIndex < EXAM_HELPER_ROUTES.length - 1 &&
    EXAM_HELPER_ROUTES[matchingRouteIndex + 1]?.path
      ? EXAM_HELPER_ROUTES[matchingRouteIndex + 1].link
      : EXAM_HELPER_ROUTES[matchingRouteIndex + 1]?.subMenuItems[0].link;

  const prevName =
    matchingRouteIndex > 0 && EXAM_HELPER_ROUTES[matchingRouteIndex - 1]?.path
      ? EXAM_HELPER_ROUTES[matchingRouteIndex - 1].name
      : EXAM_HELPER_ROUTES[matchingRouteIndex - 1]?.subMenuItems[0].name;
  const nextName =
    matchingRouteIndex < EXAM_HELPER_ROUTES.length - 1 &&
    EXAM_HELPER_ROUTES[matchingRouteIndex + 1]?.path
      ? EXAM_HELPER_ROUTES[matchingRouteIndex + 1].name
      : EXAM_HELPER_ROUTES[matchingRouteIndex + 1]?.subMenuItems[0].name;
  return (
    <div className={styles.wrapper}>
      {children}
      {matchingRoute && (
        <div className={styles.navigation}>
          {matchingRouteIndex > 0 && (
            <Link
              to={`${examId ? `${prevLink}/${examId}` : prevLink}`}
              data-noprint={true}
            >
              <Button variant="secondary">
                <i className="fas fa-chevron-left mr-2" /> {prevName}
              </Button>
            </Link>
          )}
          {matchingRouteIndex < EXAM_HELPER_ROUTES.length - 1 && (
            <Link to={`${examId ? `${nextLink}/${examId}` : nextLink}`}>
              <Button variant="primary" data-noprint={true}>
                {nextName}
                <i className="fas fa-chevron-right ml-2" />
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default ContentWrapper;
