import { EXAM_GUIDE_BREATH_COMMAND } from "../../../../Pages/Consts/ExamHelperRoutes";
import TwoPartsDynamic from "../../../../Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import ExamsSection from "../ExamsSection";
import ExamHelperContentWrapper from "../../../../Blueprints/ExamHelper/ContentWrapper/ContentWrapper";
import { useSpeech } from "react-text-to-speech";
import { useExamination } from "../../../../Utilities/FetchHooks/Ris/RisHooks";
import useCommandSteps from "./useCommandSteps/useCommandSteps";
import useActiveExam from "../../Hooks/useActiveExam";

import styles from "./BreathCommand.module.scss";

const VOICES_MAP = {
  pl: {
    lang: "pl-PL",
    voiceUri: "Google polski",
  },
  de: {
    lang: "de-DE",
    voiceUri: "Google Deutsch",
  },
  en: {
    lang: "en-US",
    voiceUri: "Google US English",
  },
};

const CMD_LANG = {
  de: "cmdDE",
  pl: "cmdPL",
  en: "cmdEN",
};

function BreathCommandContent({ examId }) {
  const examData = useExamination({ examId, enabled: !!examId });
  const commands = useCommandSteps(examData?.data?.pat_cmd);

  return (
    <ExamHelperContentWrapper>
      <div className={styles.heading}>
        <h1>
          {examData?.data?.designationde
            ? examData?.data?.designationde
            : examData.isFetching
            ? "Loading..."
            : "Breath command"}
        </h1>
      </div>
      <div className={styles.tileWrapper}>
        {commands && commands.map((command) => <StepTile command={command} />)}
      </div>
    </ExamHelperContentWrapper>
  );
}

function StepTile({ command, idx }) {
  const langPicker = CMD_LANG[window.conf.LANG.toLowerCase()] || CMD_LANG.en;
  const { lang, voiceUri } =
    VOICES_MAP[window.conf.LANG.toLowerCase()] || VOICES_MAP.en;
  const {
    speechStatus, // String that stores current speech status
    start, // Function to start the speech or put it in queue
    stop, // Function to stop the speech or remove it from queue
  } = useSpeech({ text: command[langPicker], voiceURI: voiceUri, lang: lang });

  const handleClick = () => {
    if (speechStatus !== "started") {
      start();
    }
    stop();
  };
  return (
    <div
      key={`${idx}${command[langPicker]}`}
      className={`${styles.stepTile}`}
      onClick={handleClick}
    >
      <div className={styles.playback}>
        {speechStatus !== "started" && (
          <i className="fas fa-play" onClick={start} />
        )}
        {speechStatus === "started" && (
          <>
            <i className="fas fa-stop" />
          </>
        )}
      </div>
      <h3 className={styles.title}>{command[langPicker]} </h3>
      {command.image && <img src={command.image} alt={command[langPicker]} />}
    </div>
  );
}

function BreathCommand(props) {
  const { activeExam, setExam } = useActiveExam({
    link: EXAM_GUIDE_BREATH_COMMAND.link,
  });

  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection selectedExam={activeExam} selectedExamSetter={setExam} />
      }
      rightComponent={<BreathCommandContent examId={activeExam} />}
    />
  );
}

export default BreathCommand;
