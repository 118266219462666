import { EXAM_GUIDE_SYSTEM_PREPARATION } from "../../../../Pages/Consts/ExamHelperRoutes";
import TwoPartsDynamic from "../../../../Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import ExamsSection from "../ExamsSection";
import ExamHelperContentWrapper from "../../../../Blueprints/ExamHelper/ContentWrapper/ContentWrapper";
import { useExamination } from "../../../../Utilities/FetchHooks/Ris/RisHooks";

import styles from "./SystemPreparation.module.scss";
import TubeTilt from "./TubeTilt/TubeTilt";
import TubeDistance from "./TubeDistance/TubeDistance";
import RecieverPos from "./RecieverPos/RecieverPos";
import { Spinner } from "react-bootstrap";
import useActiveExam from "../../Hooks/useActiveExam";

const LANG_ACCESSOR = "designationde";

function SystemPreparationContent({ examId }) {
  const examData = useExamination({ examId: examId, enabled: examId !== null });
  return (
    <ExamHelperContentWrapper>
      <h2>
        {examData.isSuccess ? (
          examData?.data[LANG_ACCESSOR] ? (
            examData.data[LANG_ACCESSOR]
          ) : (
            "Unable to load data"
          )
        ) : examData.isLoading ? (
          <Spinner animation="border" />
        ) : (
          "Please select an exam"
        )}
      </h2>
      <div className={styles.wrapper}>
        <div>
          <h2>Reciever position</h2>
          <RecieverPos examData={examData?.data} />
        </div>
        <div>
          <h2>Tube distance</h2>
          <TubeDistance examData={examData?.data} />
        </div>
        <div>
          <h2>Tube tilt</h2>
          <TubeTilt examData={examData?.data} />
        </div>
      </div>
    </ExamHelperContentWrapper>
  );
}

function SystemPreparation(props) {
  const { activeExam, setExam } = useActiveExam({
    link: EXAM_GUIDE_SYSTEM_PREPARATION.link,
  });

  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection selectedExam={activeExam} selectedExamSetter={setExam} />
      }
      rightComponent={<SystemPreparationContent examId={activeExam} />}
    />
  );
}

export default SystemPreparation;
