import "./SidHorizontal.scss";

function SidHorizontal({ distance }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 434.2 449.9"
    >
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          data-name="Unbenannter Verlauf"
          x1="366.2"
          y1="352.5"
          x2="429.5"
          y2="352.5"
          gradientTransform="translate(-38.8 -174.4) rotate(90.1) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#302f2e" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#000" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_2"
          data-name="Unbenannter Verlauf 2"
          x1="357.4"
          y1="412.6"
          x2="432.3"
          y2="412.6"
          gradientTransform="translate(-38.2 -175) rotate(90.2) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#302f2e" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#000" />
        </linearGradient>
      </defs>
      <g>
        <g id="Ebene_1">
          <g id="Ebene_2">
            <rect
              className="sid_horiz-3"
              x="-.5"
              y="14.7"
              width="436.2"
              height="420.8"
              transform="translate(-7.9 442.4) rotate(-89.9)"
            />
            <line
              className="sid_horiz-4"
              x1="32.4"
              y1="398.3"
              x2="33.2"
              y2="43.3"
            />
            <polygon
              className="sid_horiz-1"
              points="46.1 223.5 91.2 201.2 82.8 217.8 115.1 217.9 115.1 229.7 83.9 229.6 91.3 246.1 46.1 223.5"
            />
            <g>
              <polygon
                className="sid_horiz-6"
                points="289.6 255.8 289.8 192.5 336.4 210.6 336.3 237.5 289.6 255.8"
              />
              <circle className="sid_horiz-2" cx="373" cy="221.4" r="37.5" />
              <line
                className="sid_horiz-5"
                x1="289.7"
                y1="223.1"
                x2="241.5"
                y2="223"
              />
            </g>
            <g className="sid_horiz-8">
              <text className="sid_horiz-7" transform="translate(125.5 208.1)">
                <tspan x="0" y="0">
                  {distance}
                </tspan>
              </text>
              <text className="sid_horiz-7" transform="translate(138.9 276.5)">
                <tspan x="0" y="0">
                  cm
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SidHorizontal;
