import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { EXAM_GUIDE_SETTING_TECHNIQUE } from "../../../../Pages/Consts/ExamHelperRoutes";
import TwoPartsDynamic from "../../../../Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import ExamsSection from "../ExamsSection";
import ExamHelperContentWrapper from "../../../../Blueprints/ExamHelper/ContentWrapper/ContentWrapper";
import { useExamination } from "../../../../Utilities/FetchHooks/Ris/RisHooks";

import styles from "./SettingTechnique.module.scss";
import useWindowSize from "../../../../Utilities/Window/windowSize";
import { Button } from "react-bootstrap";
import PmIcon from "../../../PmIcon/PmIcon";
import useActiveExam from "../../Hooks/useActiveExam";

const BMI_CATEGORIES = {
  0: {
    label: "Underweight",
    color: "#ffaa28",
    kvSelector: "kvfrom",
    masSelector: "masfrom",
  },
  1: {
    label: "Normal weight",
    color: "#00c853",
    kvSelector: "kv_avg",
    masSelector: "mas_avg",
  },
  2: {
    label: "Overweight",
    color: "#ff5252",
    kvSelector: "kvtill",
    masSelector: "mastill",
  },
};

function ExpositionParams({
  squareSize,
  width,
  examData,
  bmiCategory,
  isTileShown,
  tileChange,
}) {
  const kvSelector = BMI_CATEGORIES[bmiCategory]?.kvSelector || null;
  const masSelector = BMI_CATEGORIES[bmiCategory]?.masSelector || null;
  return (
    <div
      className={`${styles.tile} ${
        isTileShown ? styles.visible : styles.hidden
      }`}
    >
      {width < 1000 && (
        <div className={`${styles.segment} ${styles.center}`}>
          <div
            onClick={() => tileChange("patient")}
            style={{ width: squareSize, height: squareSize }}
            className={`${styles.clickable} ${styles.square}`}
          >
            <i className="fas fa-male fa-5x" />
          </div>
        </div>
      )}
      <div className={styles.segmentTriangle}>
        <div
          className={styles.square}
          style={{ width: squareSize, height: squareSize }}
        >
          <h3>
            {examData && kvSelector
              ? examData[kvSelector]
              : examData
              ? examData.kv_avg
              : "..."}{" "}
            kV
          </h3>
        </div>
        <div
          className={styles.square}
          style={{ width: squareSize, height: squareSize }}
        >
          {" "}
          {examData && examData.xparam === 1 ? (
            examData.chambers === 2 ? (
              <PmIcon iconName="singleChamber" customSize="130px" />
            ) : (
              <PmIcon iconName="dualChamber" customSize="130px" />
            )
          ) : (
            <h3>
              {examData && masSelector
                ? examData[masSelector]?.toFixed(1)
                : examData
                ? examData.mas_avg.toFixed(1)
                : "..."}{" "}
              mAs
            </h3>
          )}
        </div>
        <div
          className={styles.square}
          style={{ width: squareSize, height: squareSize }}
        >
          {examData && examData.focalspot > 1 ? (
            <PmIcon iconName="focusLarge" customSize="130px" />
          ) : (
            <PmIcon iconName="focusSmall" customSize="130px" />
          )}
        </div>
      </div>
    </div>
  );
}

function PatientParams({ saveHandler, width, isTileShown, tileChange }) {
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  // const [objectDiameter, setObjectDiameter] = useState(0); // not used
  const [quickSelect, setQuickSelect] = useState(null);
  const bmi = ((weight / (height / 100) ** 2).toFixed(1) || 0).toString();
  const bmiCategory = bmi < 18.5 ? 0 : bmi < 25 ? 1 : bmi >= 25 ? 2 : null;
  useEffect(() => {
    if (quickSelect !== null) {
      saveHandler(quickSelect);
    } else {
      saveHandler(bmiCategory);
    }
  }, [bmiCategory, saveHandler, quickSelect]);

  const quickSelectClick = (value) => {
    setQuickSelect(quickSelect === value ? null : value);
    tileChange("exposition");
  };
  return (
    <div
      className={`${styles.tile} ${
        isTileShown ? styles.visible : styles.hidden
      }`}
    >
      <div className={styles.segment}>
        <div
          className={`${styles.clickable} ${styles.square}`}
          style={{
            width: "90px",
            height: "90px",
            backgroundColor: `${quickSelect === 0 ? "#ffaa28" : ""}`,
          }}
          onClick={() => quickSelectClick(0)}
        >
          <i className="fas fa-male fa-3x" />
        </div>
        <div
          className={`${styles.clickable} ${styles.square}`}
          style={{
            width: "90px",
            height: "90px",
            backgroundColor: `${quickSelect === 1 ? "#ffaa28" : ""}`,
          }}
          onClick={() => quickSelectClick(1)}
        >
          <i className="fas fa-male fa-3x" />
        </div>
        <div
          className={`${styles.clickable} ${styles.square}`}
          style={{
            width: "90px",
            height: "90px",
            backgroundColor: `${quickSelect === 2 ? "#ffaa28" : ""}`,
          }}
          onClick={() => quickSelectClick(2)}
        >
          <i className="fas fa-male fa-3x" />
        </div>
      </div>
      <div className={`${styles.segment} ${styles.rows}`}>
        <div className={styles.inputRow}>
          <div>Height</div>
          <div className={styles.labelInput}>
            <input type="number" onChange={(e) => setHeight(e.target.value)} />
            <span>cm</span>
          </div>
        </div>
        <div className={styles.inputRow}>
          <div>Weight</div>
          <div className={styles.labelInput}>
            <input type="number" onChange={(e) => setWeight(e.target.value)} />
            <span>kg</span>
          </div>
        </div>
        <div className="text-center">
          <h3
            style={{
              color: !isNaN(bmi) ? BMI_CATEGORIES[bmiCategory].color : "#000",
            }}
          >
            BMI = <span>{!isNaN(bmi) ? bmi : "no data"}</span>
          </h3>
          {!isNaN(bmi) && <h5>{BMI_CATEGORIES[bmiCategory].label}</h5>}
        </div>
        <div className={styles.inputRow}>
          <div>Object diameter</div>
          <div className={styles.labelInput}>
            <input type="number" />
            <span>cm</span>
          </div>
        </div>
      </div>
      {width < 1000 && (
        <div className="d-flex justify-content-between mt-auto w-100">
          <Button
            variant="danger"
            onClick={() => {
              setHeight(0);
              setWeight(0);
              setQuickSelect(null);
              tileChange("exposition");
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              tileChange("exposition");
            }}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

function SettingTechniqueContent({ examId }) {
  const examData = useExamination({ examId, enabled: !!examId });
  const [patData, setPatData] = useState(null);
  const [tileShown, setTileShown] = useState("exposition");
  const { width } = useWindowSize();
  const squareSize = width < 1000 ? "120px" : "200px";

  return (
    <ExamHelperContentWrapper>
      <div className={styles.heading}>
        <h1>
          {examData.isLoading && "Loading..."}
          {examData?.data?.designationde
            ? examData?.data?.designationde
            : "Setting Technique"}
        </h1>
      </div>
      <div className={styles.tileWrapper}>
        {examId ? (
          <>
            <ExpositionParams
              squareSize={squareSize}
              width={width}
              examData={examData?.data}
              bmiCategory={patData}
              isTileShown={width > 1000 ? true : tileShown === "exposition"}
              tileChange={setTileShown}
            />
            <PatientParams
              saveHandler={setPatData}
              width={width}
              isTileShown={width > 1000 ? true : tileShown === "patient"}
              tileChange={setTileShown}
            />
          </>
        ) : (
          <div className={styles.noData}>
            <h3>Select examination first</h3>
          </div>
        )}
      </div>
    </ExamHelperContentWrapper>
  );
}

function SettingTechnique(props) {
  const { activeExam, setExam } = useActiveExam({
    link: EXAM_GUIDE_SETTING_TECHNIQUE.link,
  });
  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection selectedExam={activeExam} selectedExamSetter={setExam} />
      }
      rightComponent={<SettingTechniqueContent examId={activeExam} />}
    />
  );
}

export default SettingTechnique;
