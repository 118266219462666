import { useState, useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useExamData from "../../../Hooks/useExamData";
import { EXAM_QA_OVERVIEW } from "../../../../../Pages/Consts/ExamHelperRoutes";
import ExamHelperContentTab from "../../../../../Blueprints/ExamHelper/ImageSwitchingTab/ImageSwitchingTab";

import styles from "./SingleQaSection.module.scss";

export default function SingleQaSection({ selectedExamId }) {
  const [currentQa, setCurrentQa] = useState(0);
  const { data, exam, ExmImg, colors, qaAnatomyCriteria, isError } =
    useExamData(selectedExamId, currentQa);
  const { push } = useHistory();

  useEffect(() => {
    setCurrentQa(0);
  }, [selectedExamId]);

  const verifiedColors = colors === null ? "" : colors;

  const leftButton = {
    disabled: currentQa === 0,
    content: <i className="fas fa-chevron-left" />,
    onClick: () => setCurrentQa((prev) => prev - 1),
  };

  const rightButton = {
    disabled: !data || currentQa === data.length,
    content: <i className="fas fa-chevron-right" />,
    onClick: () =>
      currentQa < data.length - 1
        ? setCurrentQa((prev) => prev + 1)
        : push(`${EXAM_QA_OVERVIEW.link}/${selectedExamId}`),
  };

  const TitleBar = ({ title }) => (
    <div className={styles.Heading}>
      <h3>{title}</h3>
      <Button
        onClick={() => push(`${EXAM_QA_OVERVIEW.link}/${selectedExamId}`)}
      >
        Komplett
        <i className="ml-2 fas fa-tasks" />
      </Button>
    </div>
  );

  const { title, image, description } = useMemo(() => {
    let data = {
      title: <h3 className={styles.title}>Please select an examination.</h3>,
      image: null,
      description: <></>,
    };
    if (!isError && selectedExamId > 0) {
      data = {
        title: <TitleBar title={exam} />,
        image: <ExmImg colors={verifiedColors} />,
        description: <h3>{qaAnatomyCriteria}</h3>,
      };
    }
    return data;
  }, [selectedExamId, currentQa, exam]);

  return (
    <ExamHelperContentTab
      title={title}
      image={image}
      description={description}
      leftButton={leftButton}
      rightButton={rightButton}
    />
  );
}
