import { useEffect, useState } from "react";
import { capitalize } from "../stringHelpers/stringHelpers";

function usePmedFilter({ field, searchPhrase = "", manual = false }) {
  const [filter, setFilter] = useState(undefined);

  const updateFilter = () => {
    setFilter(undefined);
    let filter = undefined;

    if (searchPhrase && searchPhrase !== "")
      if (searchPhrase.includes(" ")) {
        searchPhrase = searchPhrase.replace(" ", "%");
      }
    if (Array.isArray(field)) {
      const filers = [];
      field.forEach((f) => {
        filers.push({
          field: f,
          op: "like",
          value: `%${searchPhrase}%`,
        });
        filers.push({
          field: f,
          op: "like",
          value: `%${searchPhrase.toLowerCase()}%`,
        });
        filers.push({
          field: f,
          op: "like",
          value: `%${searchPhrase.toUpperCase()}%`,
        });
        filers.push({
          field: f,
          op: "like",
          value: `%${capitalize(searchPhrase)}%`,
        });
      });
      filter = {
        or: filers,
      };
    } else {
      filter = {
        or: [
          {
            field: field,
            op: "like",
            value: `%${searchPhrase}%`,
          },
          {
            field: field,
            op: "like",
            value: `%${searchPhrase.toLowerCase()}%`,
          },
          {
            field: field,
            op: "like",
            value: `%${searchPhrase.toUpperCase()}%`,
          },
          {
            field: field,
            op: "like",
            value: `%${capitalize(searchPhrase)}%`,
          },
        ],
      };
    }
    setFilter(filter);
  };

  useEffect(() => {
    !manual && updateFilter();
  }, [searchPhrase, field]);

  return { filter, updateFilter };
}

export default usePmedFilter;
