import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import useWindowSize from "../../../Utilities/Window/windowSize";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import {
  loadPatient,
  getGenderIcon,
  ageCalc,
  yearsCalc,
} from "../../../Utilities/Patients/PatientUtils";
import { useKeycloak } from "@react-keycloak/web";
import { NotificationManager } from "react-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import SelectPatientModal from "../../SelectPatientModal/SelectPatientModal";
import PregnacyCheck from "./PregnacyCheck";
import {
  setOpenedPatient,
  clearOpenedPatient,
  setExamplannerPatientId,
} from "../../../Redux/actions";
import "./stylesheets/PatientInfoTopBar.scss";
import { MakeGetObject } from "../../../Utilities/AuthHeader";
import { useTranslation } from "react-i18next";
import useQuery from "../../../Utilities/Hooks/useQuery"

export default function PatientInfoTopBar() {
  const windowSize = useWindowSize();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const [selectPatientModalShown, setSelectPatientModalShown] = useState(false);
  const [patientPregnancyCheckModalShown, setPatientPregnancyCheckModalShown] =
    useState(false);
  const [patientData, setPatientData] = useState(undefined);
  const journalSelectedPatients = useSelector(
    (state) => state.examManager.openedPatients
  );
  let patId = new URLSearchParams(window.location.search).get("patid");

  const searchParams = useQuery();
  const { t } = useTranslation(["addpatientform", "common"]);

  if (searchParams.get("PatientIDs")) {
    const patid_arr = searchParams.get("PatientIDs").split(",");

    if (patid_arr && patid_arr.length === 1) {
      patid_arr.forEach((patientid) => {
        fetch(
          `
        ${
          window.conf.SERVER_CONFIG.BASE_URL
        }/ris/patients?&search=${encodeURIComponent(
            JSON.stringify({ field: "patientid", op: "like", value: patientid })
          )}`,
          MakeGetObject(keycloak)
        )
          .then((response) => response.json())
          .then((rdata) => {
            if (rdata.patients) {
              if (rdata.patients[0]) {
                const dapatid = rdata.patients[0].id;
                const selected_pat_ids = journalSelectedPatients;
                if (dapatid && !selected_pat_ids.includes(dapatid)) {
                  dispatch(setOpenedPatient(dapatid));
                  setSelectPatientModalShown(false);
                }
              }
            } else {
              //Not existing patient, create new one
              if (
                searchParams.get("department_id") &&
                searchParams.get("birth_date") &&
                searchParams.get("patientssex") &&
                searchParams.get("patientsname") &&
                searchParams.get("patientsvorname") &&
                searchParams.get("street") &&
                searchParams.get("zip") &&
                searchParams.get("city")
              ) {
                const new_patient = {
                  id: patientid,
                  kontoid: searchParams.get("department_id"),
                  gebdat: searchParams.get("birth_date"),
                  patientssex: searchParams.get("patientssex"),
                  patientsname: searchParams.get("patientsname"),
                  patientsvorname: searchParams.get("patientsvorname"),
                  street: searchParams.get("street"),
                  zip: searchParams.get("zip"),
                  city: searchParams.get("city"),
                };

                const requestOptions = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ' + ${keycloak.token}`,
                  },
                  body: JSON.stringify({
                    ...new_patient,
                  }),
                };
                fetch(
                  `${window.conf.SERVER_CONFIG.BASE_URL}/ris/patients`,
                  requestOptions
                )
                  .then((response) => {
                    // check for error response
                    if (!response.ok) {
                      // get error message from body or default to response status
                      const error = response.status;
                      return Promise.reject(error);
                    } else {
                      return response.json();
                    }
                  })
                  .then((rjson) => {
                    //dispatch(setOpenedPatient(rjson.id));
                    NotificationManager.success(
                      `${t("notification.addSuccess")}`
                    );
                  })
                  .catch((error) => {
                    NotificationManager.error(
                      `${t("notification.addError")} ${error}`
                    );
                  });
              }
            }
          });
      });
    }
  }

  const patientId = patId
    ? patId
    : journalSelectedPatients.length > 0
    ? journalSelectedPatients[journalSelectedPatients.length - 1]
    : undefined;

  const selectPatientHandler = (patData) => {
    dispatch(clearOpenedPatient());
    dispatch(setExamplannerPatientId(patData.id));
    dispatch(setOpenedPatient(patData.id));
    setSelectPatientModalShown(false);
  };

  const loadPatientInfo = () => {
    if (patientId !== null && patientId !== undefined) {
      dispatch(setExamplannerPatientId(patientId));
      loadPatient(keycloak, patientId)
        .then((data) => {
          setPatientData(data);
          let patAge = yearsCalc(data.gebdat);
          if (data.patientssex === "F" && patAge > 12 && patAge < 70) {
            setPatientPregnancyCheckModalShown(true);
          }
        })
        .catch((reason) => {
          console.warn("Unable to load patient topBar", reason);
          NotificationManager.warning("Unable to load patient");
          setSelectPatientModalShown(true);
        });
    } else {
      setSelectPatientModalShown(true);
    }
  };

  const getPatientHeaderHeight = () => {
    if (windowSize.width < 1200) {
      return "10vh";
    } else {
      return "7vh";
    }
  };

  const patientPregnantContinueExamHandler = () => {
    //store information on consent for pregnant examination
    setPatientPregnancyCheckModalShown(false);
  };

  const patientPregnantRejectExamHandler = () => {
    dispatch(clearOpenedPatient());
    setPatientPregnancyCheckModalShown(false);
  };

  //on init works
  useEffect(() => {
    loadPatientInfo();
  }, []);

  //on patient list change
  useEffect(() => {
    loadPatientInfo();
  }, [journalSelectedPatients, patId]);

  return (
    <>
      <SelectPatientModal
        shown={selectPatientModalShown}
        close={() => {
          setSelectPatientModalShown(false);
        }}
        amount={5}
        rowClickHandler={selectPatientHandler}
      />
      <PregnacyCheck
        shown={patientPregnancyCheckModalShown}
        close={() => {
          setPatientPregnancyCheckModalShown(false);
        }}
        yesHandler={patientPregnantContinueExamHandler}
        noHandler={patientPregnantRejectExamHandler}
      />
      <div
        className="d-flex w-100 bg-white align-items-center rounded"
        name="PatientHeader"
        style={{ height: getPatientHeaderHeight() }}
      >
        {patientId !== undefined ? (
          <>
            {patientData !== undefined ? (
              windowSize.width >= 980 ? (
                <>
                  <div className="d-flex flex-column ml-3 mr-4">
                    <div className="small text-muted">Bio. Gender</div>
                    <div className="font-weight-bold">
                      <h4>{getGenderIcon(patientData.patientssex, "fa-md")}</h4>
                    </div>
                  </div>
                  <div className="d-flex flex-column ml-2 mr-4">
                    <div className="small text-muted">PatientID</div>
                    <div className="font-weight-bold">
                      <h4>{patientData.patientid}</h4>
                    </div>
                  </div>
                  <div className="d-flex flex-column ml-2 mr-4">
                    <div className="small text-muted">Last Name</div>
                    <div className="font-weight-bold">
                      <h4>{patientData.patientsname}</h4>
                    </div>
                  </div>
                  <div className="d-flex flex-column ml-2 mr-4">
                    <div className="small text-muted">First Name</div>
                    <div className="font-weight-bold">
                      <h4>{patientData.patientsvorname}</h4>
                    </div>
                  </div>
                  <div className="d-flex flex-column ml-2 mr-4">
                    <div className="small text-muted">Birth Date</div>
                    <div className="font-weight-bold">
                      <h4>{patientData.gebdat}</h4>
                    </div>
                  </div>
                  <div className="d-flex flex-column ml-2 mr-4">
                    <div className="small text-muted">Age</div>
                    <div className="font-weight-bold">
                      <h4>{ageCalc(patientData.gebdat)}</h4>
                    </div>
                  </div>
                  <Button
                    className="ml-auto mr-3"
                    onClick={() => {
                      setSelectPatientModalShown(true);
                    }}
                  >
                    <i className="fa fa-user-alt-slash fa-lg" />
                  </Button>
                </>
              ) : (
                <>
                  <div className="d-flex flex-column ml-2 mr-1">
                    <div className="font-weight-bold">
                      <h4>
                        {patientData.patientsvorname} {patientData.patientsname}
                      </h4>
                    </div>
                  </div>
                  <div className="drop-info">
                    <OverlayTrigger
                      trigger="focus"
                      placement="bottom"
                      overlay={
                        <Popover id="patient-details-pop">
                          <Popover.Title as="h3">Patient details</Popover.Title>
                          <Popover.Content className="my-2">
                            <div className="d-flex flex-column ml-3 mr-4">
                              <div className="small text-muted">
                                Bio. Gender
                              </div>
                              <div className="font-weight-bold">
                                <h4>
                                  {getGenderIcon(
                                    patientData.patientssex,
                                    "fa-md"
                                  )}
                                </h4>
                              </div>
                            </div>
                            {window.conf.LANG === "PL" && (
                              <div className="d-flex flex-column ml-2 mr-4">
                                <div className="small text-muted">PESEL</div>
                                <div className="font-weight-bold">
                                  <h4>{patientData.patientid}</h4>
                                </div>
                              </div>
                            )}
                            <div className="d-flex flex-column ml-2 mr-4">
                              <div className="small text-muted">Last Name</div>
                              <div className="font-weight-bold">
                                <h4>{patientData.patientsname}</h4>
                              </div>
                            </div>
                            <div className="d-flex flex-column ml-2 mr-4">
                              <div className="small text-muted">First Name</div>
                              <div className="font-weight-bold">
                                <h4>{patientData.patientsvorname}</h4>
                              </div>
                            </div>
                            <div className="d-flex flex-column ml-2 mr-4">
                              <div className="small text-muted">Birth Date</div>
                              <div className="font-weight-bold">
                                <h4>{patientData.gebdat}</h4>
                              </div>
                            </div>
                            <div className="d-flex flex-column ml-2 mr-4">
                              <div className="small text-muted">Age</div>
                              <div className="font-weight-bold">
                                <h4>{ageCalc(patientData.gebdat)}</h4>
                              </div>
                            </div>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Button size="sm" className="mr-1">
                        <i className="fa fa-chevron-down fa-sm" />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <Button
                    size="sm"
                    className="ml-auto mr-3"
                    onClick={() => {
                      setSelectPatientModalShown(true);
                    }}
                  >
                    <i className="fa fa-user-alt-slash " />
                  </Button>
                </>
              )
            ) : (
              <ClipLoader size={40} />
            )}
          </>
        ) : (
          <>
            <div className="ml-3">
              <h5>No patient selected</h5>
            </div>
            <Button
              className="ml-auto mr-3"
              onClick={() => {
                setSelectPatientModalShown(true);
              }}
            >
              <i className="fa fa-user-alt fa-lg" />
            </Button>
          </>
        )}
      </div>
    </>
  );
}
