import "./LayingCranial.scss";

export default function LayingCranial({ angle }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 523.8 545.5"
    >
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          data-name="Unbenannter Verlauf"
          x1="812.4"
          y1="622.1"
          x2="753.5"
          y2="622.1"
          gradientTransform="translate(885.9 -348.1) rotate(90)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".3" stop-color="#000" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf-2"
          data-name="Unbenannter Verlauf"
          x1="105.4"
          y1="251.7"
          x2="103.4"
          y2="251.7"
          gradientTransform="translate(363.4) rotate(-180) scale(1 -1)"
        />
        <linearGradient
          id="Unbenannter_Verlauf_2"
          data-name="Unbenannter Verlauf 2"
          x1="930.1"
          y1="848"
          x2="993.7"
          y2="848"
          gradientTransform="translate(-317.8 1295.2) rotate(-20.7) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_3"
          data-name="Unbenannter Verlauf 3"
          x1="919.4"
          y1="906.5"
          x2="994.7"
          y2="906.5"
          gradientTransform="translate(-317.7 1295.2) rotate(-20.7) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
      </defs>
      <g>
        <g id="Ebene_1">
          <g id="a">
            <rect
              className="laying_cranial_tube_tilt-3"
              x="42.9"
              y="54.1"
              width="443.9"
              height="449.4"
            />
            <rect
              className="laying_cranial_tube_tilt-1"
              x="55.4"
              y="458.1"
              width="415.7"
              height="26.4"
            />
            <g>
              <path
                className="laying_cranial_tube_tilt-5"
                d="M388.4,460.9c2-.5,17.8-7.7,22.9-14.5,12-1.8,25.2,9.1,34.8,8.9,16.2-1.5,19.1-5.5,19.5-21-1.8-19.1-18.2-24.2-19.5-23.5-2.2,1.2-4.2,1.3-5.5.6-4.3-2.5-5.8-5.6-9.8-4.9l-.3,4.3c-3.4-1.2-5.8-.8-6.6-.3-.3,1.1-1.2.8-1.8,1.5-2.2.2-3.2.3-5.8.5-1,0-2.3,2.9-.6,9.5.7.1-3.5,8.4-22.4,6.1-35.1-26.5-45.1-14.2-45.1-14.2l-100.9,4.9c-18.3-1.8-62.5,9-75.6,11.8-28.1,6.2-67.2,6.6-88,6.7-18-6.4-15.4-20.8-19.1-30.4-4.6-4.9-1.6,47.7-2.3,43.3,1.5,4.6,11.1-.6,23.8-.5,17.2-.1,34.6,6.6,51.6,7.3,13.9.1,25.9-.5,33.2-3.2,17.1,4,35.9,4.3,46.4,3.5,5.6.4,13-.7,23.1-4,11.7,5.1,18.5,7.9,25.9,9.6,13.9,2.6,21.4-10.1,39.7-10.1,8.7-1.4,34,8.4,58.9,10.1l23.6-2h-.1Z"
              />
              <line
                className="laying_cranial_tube_tilt-4"
                x1="260.4"
                y1="110.7"
                x2="260.4"
                y2="116.7"
              />
              <line
                className="laying_cranial_tube_tilt-4"
                x1="260.4"
                y1="381"
                x2="260.4"
                y2="387"
              />
            </g>
            <g>
              <line
                className="laying_cranial_tube_tilt-6"
                x1="259"
                y1="375.2"
                x2="259"
                y2="128.2"
              />
              <line
                className="laying_cranial_tube_tilt-4"
                x1="456.4"
                y1="353.3"
                x2="258.7"
                y2="108.6"
              />
              <g>
                <path
                  className="laying_cranial_tube_tilt-4"
                  d="M443.6,358.9c-54.7,27-115.1,35.1-179.2,27.3"
                />
                <path
                  className="laying_cranial_tube_tilt-8"
                  d="M454.3,353.3c-4,4.5-8.7,10.9-10.8,16.3l-1.3-10-7.4-6.9c5.7,1.4,13.6,1.3,19.6.6h0Z"
                />
              </g>
              <g>
                <polygon
                  className="laying_cranial_tube_tilt-2"
                  points="285.7 136.5 260.3 145.5 260.2 193.8 320.1 172.4 285.7 136.5"
                />
                <path
                  className="laying_cranial_tube_tilt-7"
                  d="M294.2,102.5c4,19.6-9.4,38.5-30,42.1s-40.5-9.4-44.5-29c-4-19.6,9.4-38.5,30-42.1,20.6-3.6,40.5,9.4,44.5,29h0Z"
                />
              </g>
            </g>
          </g>
          <text
            className="laying_cranial_tube_tilt-9"
            transform="translate(276.7 361)"
          >
            <tspan x="0" y="0">
              {angle}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}
