import "./SidVertical.scss";

function SidVertical({ distance }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 450.8 435.4"
    >
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          data-name="Unbenannter Verlauf"
          x1="188.8"
          y1="580.2"
          x2="255.4"
          y2="580.2"
          gradientTransform="translate(0 715) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#302f2e" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#000" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_2"
          data-name="Unbenannter Verlauf 2"
          x1="179.3"
          y1="643.3"
          x2="258.2"
          y2="643.3"
          gradientTransform="translate(0 715.1) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#302f2e" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#000" />
        </linearGradient>
      </defs>
      <g>
        <g id="Ebene_1">
          <g id="Ebene_1-2" data-name="Ebene_1">
            <g id="Ebene_2">
              <rect
                className="sid_vert-6"
                x="7.2"
                y="7.4"
                width="436.2"
                height="420.8"
              />
              <line
                className="sid_vert-3"
                x1="400.2"
                y1="404.6"
                x2="45.2"
                y2="404.6"
              />
              <polygon
                className="sid_vert-1"
                points="221 391.3 194.4 337.7 214.1 347.6 214.1 309.1 228.2 309.1 228.2 346.3 247.8 337.5 221 391.3"
              />
              <polygon
                className="sid_vert-5"
                points="255.4 159.4 188.8 159.4 207.7 110.3 236.1 110.3 255.4 159.4"
              />
              <circle className="sid_vert-2" cx="218.7" cy="71.9" r="39.4" />
              <line
                className="sid_vert-4"
                x1="221"
                y1="159.5"
                x2="221"
                y2="210.1"
              />
            </g>
            <g className="sid_vert-8">
              <text className="sid_vert-7" transform="translate(133.9 277.1)">
                <tspan x="0" y="0">
                  {distance} cm
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SidVertical;
