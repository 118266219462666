import "./LayingCaudal.scss";

function LayingCaudal({ angle }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 523.8 545.5"
    >
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          data-name="Unbenannter Verlauf"
          x1="812.4"
          y1="622.1"
          x2="753.5"
          y2="622.1"
          gradientTransform="translate(885.9 -348.1) rotate(90)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".3" stop-color="#000" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_2"
          data-name="Unbenannter Verlauf 2"
          x1="784.2"
          y1="903.1"
          x2="847.8"
          y2="903.1"
          gradientTransform="translate(681.2 1295.2) rotate(-159.3)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_3"
          data-name="Unbenannter Verlauf 3"
          x1="773.5"
          y1="961.6"
          x2="848.8"
          y2="961.6"
          gradientTransform="translate(681.1 1295.2) rotate(-159.3)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
      </defs>
      <g>
        <g id="Ebene_1">
          <g id="a">
            <rect
              className="laying_caudal_tube_tilt-3"
              x="42.9"
              y="54.1"
              width="443.9"
              height="449.4"
            />
            <rect
              className="laying_caudal_tube_tilt-1"
              x="55.4"
              y="458.1"
              width="415.7"
              height="26.4"
            />
            <g>
              <line
                className="laying_caudal_tube_tilt-4"
                x1="260.4"
                y1="110.7"
                x2="260.4"
                y2="116.7"
              />
              <line
                className="laying_caudal_tube_tilt-6"
                x1="260.4"
                y1="128.2"
                x2="260.4"
                y2="375.2"
              />
              <line
                className="laying_caudal_tube_tilt-4"
                x1="260.4"
                y1="381"
                x2="260.4"
                y2="387"
              />
            </g>
            <path
              className="laying_caudal_tube_tilt-5"
              d="M388.4,460.9c2-.5,17.8-7.7,22.9-14.5,12-1.8,25.2,9.1,34.8,8.9,16.2-1.5,19.1-5.5,19.5-21-1.8-19.1-18.2-24.2-19.5-23.5-2.2,1.2-4.2,1.3-5.5.6-4.3-2.5-5.8-5.6-9.8-4.9l-.3,4.3c-3.4-1.2-5.8-.8-6.6-.3-.3,1.1-1.2.8-1.8,1.5-2.2.2-3.2.3-5.8.5-1,0-2.3,2.9-.6,9.5.7.1-3.5,8.4-22.4,6.1-35.1-26.5-45.1-14.2-45.1-14.2l-100.9,4.9c-18.3-1.8-62.5,9-75.6,11.8-28.1,6.2-67.2,6.6-88,6.7-18-6.4-15.4-20.8-19.1-30.4-4.6-4.9-1.6,47.7-2.3,43.3,1.5,4.6,11.1-.6,23.8-.5,17.2-.1,34.6,6.6,51.6,7.3,13.9.1,25.9-.5,33.2-3.2,17.1,4,35.9,4.3,46.4,3.5,5.6.4,13-.7,23.1-4,11.7,5.1,18.5,7.9,25.9,9.6,13.9,2.6,21.4-10.1,39.7-10.1,8.7-1.4,34,8.4,58.9,10.1l23.6-2h-.1Z"
            />
            <line
              className="laying_caudal_tube_tilt-4"
              x1="260.7"
              y1="108.6"
              x2="63"
              y2="353.3"
            />
            <g>
              <path
                className="laying_caudal_tube_tilt-4"
                d="M255,386.2c-64.1,7.8-124.5-.3-179.2-27.3"
              />
              <path
                className="laying_caudal_tube_tilt-8"
                d="M65,353.3c6,.7,13.9.8,19.6-.6l-7.4,6.9-1.3,10c-2.1-5.4-6.8-11.8-10.8-16.3h0Z"
              />
            </g>
            <g>
              <polygon
                className="laying_caudal_tube_tilt-2"
                points="199.3 172.4 259.2 193.8 259.1 145.5 233.7 136.5 199.3 172.4"
              />
              <path
                className="laying_caudal_tube_tilt-7"
                d="M225.2,102.5c4-19.6,23.9-32.6,44.5-29,20.6,3.6,34,22.5,30,42.1s-23.9,32.6-44.5,29-34-22.5-30-42.1h0Z"
              />
            </g>
          </g>
          <text
            className="laying_caudal_tube_tilt-9"
            transform="translate(128.7 348)"
          >
            <tspan x="0" y="0">
              {angle}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default LayingCaudal;
