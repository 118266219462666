import TwoPartsDynamic from "../../../../Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import ExamsSection from "../ExamsSection";
import { EXAM_GUIDE_PATIENT_PREP } from "../../../../Pages/Consts/ExamHelperRoutes";
import ExamHelperContentWrapper from "../../../../Blueprints/ExamHelper/ContentWrapper/ContentWrapper";
import useExamPreparationImage from "./hooks/useExamPreparationImage";
import { useExamination } from "../../../../Utilities/FetchHooks/Ris/RisHooks";
import useExamPreparationDirections from "./hooks/useExamPreparationDirections";
import { useState } from "react";
import { useSpeech } from "react-text-to-speech";
import useActiveExam from "../../Hooks/useActiveExam";

import styles from "./PatientPreparation.module.scss";
import { Button } from "react-bootstrap";

const VOICES_MAP = {
  pl: {
    lang: "pl-PL",
    voiceUri: "Google polski",
  },
  de: {
    lang: "de-DE",
    voiceUri: "Google Deutsch",
  },
};

function PatPrepContent({ examId }) {
  const [selectedGender, setSelectedGender] = useState("woman");
  const examData = useExamination({ examId, enabled: !!examId });
  const image = useExamPreparationImage(examId, selectedGender);
  const directions = useExamPreparationDirections(examData?.data?.pat_prep);
  const voiceToUse =
    VOICES_MAP[window.conf.LANG.toLowerCase()] || VOICES_MAP.de;

  return (
    <ExamHelperContentWrapper>
      <div className={styles.heading}>
        <h1>
          {" "}
          {examData?.data?.designationde
            ? examData?.data?.designationde
            : "Patient Preparation"}
        </h1>
        <Button
          onClick={() => {
            setSelectedGender((prev) => (prev === "woman" ? "men" : "woman"));
          }}
        >
          <i
            className={`fas ${
              selectedGender === "woman" ? "fa-venus" : "fa-mars"
            } fa-2x`}
          />
        </Button>
      </div>
      {image && (
        <div className={styles.imageWrapper}>
          <img src={image} alt="Patient Preparation" />
          <div className={styles.directionsWrapper}>
            {directions &&
              directions.map((direction, index) => (
                <SpeechBox
                  direction={direction}
                  index={index}
                  voiceToUse={voiceToUse}
                />
              ))}
            <NoJewelrlyBox voiceToUse={voiceToUse} />
          </div>
        </div>
      )}
    </ExamHelperContentWrapper>
  );
}

function PatientPreparation(props) {
  const { activeExam, setExam } = useActiveExam({
    link: EXAM_GUIDE_PATIENT_PREP.link,
  });

  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection selectedExam={activeExam} selectedExamSetter={setExam} />
      }
      rightComponent={<PatPrepContent examId={activeExam} />}
    />
  );
}

export default PatientPreparation;

function SpeechBox({ direction, index, voiceToUse }) {
  const {
    speechStatus, // String that stores current speech status
    start, // Function to start the speech or put it in queue
    stop, // Function to stop the speech or remove it from queue
  } = useSpeech({
    text: direction,
    voiceURI: voiceToUse.voiceUri,
    lang: voiceToUse.lang,
  });

  return (
    <div
      key={index}
      className={styles.direction}
      style={{ gap: "20px" }}
      onClick={() => {
        if (speechStatus !== "started") {
          start();
        }
        stop();
      }}
    >
      <div className={styles.directionTitle}>{direction}</div>
      <div>
        {speechStatus !== "started" ? (
          <i className="fas fa-play" onClick={start} />
        ) : (
          <i className="fas fa-stop" onClick={stop} />
        )}
      </div>
    </div>
  );
}

function NoJewelrlyBox({ voiceToUse }) {
  const TEXT = "Piercing und Schmuck im Aufnahmebereich ablegen !";
  const {
    speechStatus, // String that stores current speech status
    start, // Function to start the speech or put it in queue
    stop, // Function to stop the speech or remove it from queue
  } = useSpeech({
    text: TEXT,
    voiceURI: voiceToUse.voiceUri,
    lang: voiceToUse.lang,
  });
  return (
    <div
      className={styles.jewelryInfo}
      onClick={() => {
        if (speechStatus !== "started") {
          start();
        }
        stop();
      }}
    >
      <i className="fas fa-info-circle" />
      <div>{TEXT}</div>
      <div className="ml-auto">
        {speechStatus !== "started" ? (
          <i className="fas fa-play" onClick={start} />
        ) : (
          <i className="fas fa-stop" onClick={stop} />
        )}
      </div>
    </div>
  );
}
