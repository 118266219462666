import "./StandingCranial.scss";

function StandingCranial({ angle }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 523.8 545.5"
    >
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          data-name="Unbenannter Verlauf"
          x1="903.8"
          y1="532.4"
          x2="844.9"
          y2="532.4"
          gradientTransform="translate(983.8 -250.2) rotate(-180) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".3" stop-color="#000" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_2"
          data-name="Unbenannter Verlauf 2"
          x1="990.9"
          y1="495.5"
          x2="1054.3"
          y2="495.5"
          gradientTransform="translate(-442.7 1035.1) rotate(-69.7)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_3"
          data-name="Unbenannter Verlauf 3"
          x1="980.2"
          y1="554"
          x2="1055.3"
          y2="554"
          gradientTransform="translate(-442.7 1035) rotate(-69.7)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
      </defs>
      <g>
        <g id="Ebene_1">
          <g id="a">
            <rect
              className="standing_cranial_tube_tilt-3"
              x="42.9"
              y="54.1"
              width="443.9"
              height="449.4"
            />
            <rect
              className="standing_cranial_tube_tilt-1"
              x="59.8"
              y="74.8"
              width="26.4"
              height="415.7"
            />
            <path
              className="standing_cranial_tube_tilt-5"
              d="M83.4,157.5l-2,23.6c1.7,24.9,11.4,50.2,10.1,58.9,0,18.3-12.7,25.8-10.1,39.7,1.7,7.3,4.5,14.1,9.6,25.9-3.3,10.1-4.3,17.5-4,23.1-.8,10.5-.5,29.3,3.5,46.4-2.7,7.4-3.4,19.4-3.2,33.2.7,17,7.5,34.3,7.3,51.6,0,12.7-5,22.3-.5,23.8-4.3-.7,48.2,2.3,43.3-2.3-9.6-3.7-24-1.1-30.4-19.1,0-20.8.5-60,6.7-88,2.8-13.1,13.5-57.3,11.8-75.6l4.9-100.9s12.4-10.1-14.2-45.1c-2.3-19,6-23.1,6.1-22.4,6.6,1.7,9.5.4,9.5-.6.2-2.5.2-3.6.5-5.8.7-.7.4-1.5,1.5-1.8.4-.8.9-3.1-.3-6.6l4.3-.3c.8-4-2.4-5.4-4.9-9.8-.7-1.3-.6-3.3.6-5.5.7-1.4-4.4-17.7-23.5-19.5-15.6.5-19.5,3.4-21,19.5-.3,9.6,10.7,22.7,8.9,34.8-6.8,5.1-14,20.9-14.5,22.9h0Z"
            />
            <g>
              <g>
                <line
                  className="standing_cranial_tube_tilt-4"
                  x1="422.4"
                  y1="270.8"
                  x2="428.4"
                  y2="270.7"
                />
                <line
                  className="standing_cranial_tube_tilt-6"
                  x1="163.8"
                  y1="272.9"
                  x2="410.9"
                  y2="270.9"
                />
                <line
                  className="standing_cranial_tube_tilt-4"
                  x1="152.1"
                  y1="273"
                  x2="158.1"
                  y2="272.9"
                />
              </g>
              <line
                className="standing_cranial_tube_tilt-4"
                x1="184.2"
                y1="75.2"
                x2="430.4"
                y2="271"
              />
              <g>
                <path
                  className="standing_cranial_tube_tilt-4"
                  d="M178.7,88.1c-26.6,55-34.3,115.4-25.9,179.4"
                />
                <path
                  className="standing_cranial_tube_tilt-8"
                  d="M184.1,77.4c-.7,6-.6,13.9.8,19.5l-6.9-7.4-10-1.2c5.4-2.2,11.8-6.9,16.2-11h-.1Z"
                />
              </g>
              <g>
                <polygon
                  className="standing_cranial_tube_tilt-2"
                  points="366.1 210.1 345.3 270.1 393.5 269.7 402.4 244.1 366.1 210.1"
                />
                <path
                  className="standing_cranial_tube_tilt-7"
                  d="M394.4,265.7c3.5-20.6,22.3-34.1,41.9-30.3s32.8,23.6,29.3,44.2-22.2,34.1-41.9,30.3c-19.6-3.8-32.8-23.6-29.3-44.2h0Z"
                />
              </g>
            </g>
          </g>
          <text
            className="standing_cranial_tube_tilt-9"
            transform="translate(165.8 234.2)"
          >
            <tspan x="0" y="0">
              {angle}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default StandingCranial;
